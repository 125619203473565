import React, { useState } from 'react';
import './App.css';

function App() {
  const [symptoms, setSymptoms] = useState('');
  const [age, setAge] = useState('');
  const [sex, setSex] = useState('');
  const [isSmoker, setIsSmoker] = useState('');
  const [isAlcoholic, setIsAlcoholic] = useState('');
  const [duration, setDuration] = useState('');
  const [severity, setSeverity] = useState('');
  const [medicalHistory, setMedicalHistory] = useState('');
  const [currentMedications, setCurrentMedications] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado de carga

  const handleSubmit = async () => {
    setIsLoading(true); // Iniciar el estado de carga
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          symptoms,
          age,
          sex,
          isSmoker,
          isAlcoholic,
          duration,
          severity,
          medicalHistory,
          currentMedications,
        }),
      };
      const response = await fetch('https://demoai.enfermedadesinfecciosas.es/consulta.php', requestOptions);
      const data = await response.json();
      setResponse(data.choices[0].message.content); // Ajusta según la estructura de respuesta de Groq
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponse('No se pudo conectar al servidor.');
    }
    setIsLoading(false); // Finalizar el estado de carga
  };

  return (
    <div className="App">
      <h1>Asistente virtual Demo 1.0</h1>
      {!response ? (
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div className="form-row">
            <div className="form-group">
              <label>Edad:</label>
              <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Sexo:</label>
              <select value={sex} onChange={(e) => setSex(e.target.value)}>
                <option value="">Seleccione</option>
                <option value="hombre">Hombre</option>
                <option value="mujer">Mujer</option>
                <option value="otro">Otro</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>¿Es fumador?</label>
              <select value={isSmoker} onChange={(e) => setIsSmoker(e.target.value)}>
                <option value="">Seleccione</option>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="form-group">
              <label>¿Es alcohólico?</label>
              <select value={isAlcoholic} onChange={(e) => setIsAlcoholic(e.target.value)}>
                <option value="">Seleccione</option>
                <option value="si">Sí</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Duración de los síntomas:</label>
              <input type="text" value={duration} onChange={(e) => setDuration(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Gravedad de los síntomas:</label>
              <select value={severity} onChange={(e) => setSeverity(e.target.value)}>
                <option value="">Seleccione</option>
                <option value="leve">Leve</option>
                <option value="moderado">Moderado</option>
                <option value="severo">Severo</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Historial médico previo:</label>
              <textarea value={medicalHistory} onChange={(e) => setMedicalHistory(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Medicamentos actuales:</label>
              <textarea value={currentMedications} onChange={(e) => setCurrentMedications(e.target.value)} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label>Síntomas:</label>
              <textarea value={symptoms} onChange={(e) => setSymptoms(e.target.value)} />
            </div>
          </div>
          <button type="submit" disabled={isLoading} className={isLoading ? 'loading' : ''}>
            {isLoading ? 'Consultando...' : 'Consultar'}
          </button>
        </form>
      ) : (
        <div className="response">
          <h3>Respuesta:</h3>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
}

export default App;
